import React from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { backend_url } from "../libs/data";
import boxcircle from "../assets/boxcircle.svg";
import { IoMdClose } from 'react-icons/io';

const PricingModelVip = ({ user, onClose }) => {
  const handlePurchaseVipPlan = async (planType = "vipPlan") => {
    try {
      const res = await axios.post(
        `${backend_url}/create-checkout-session-plan`,
        {
          price: "99",
          email: user.email,
          sessionType: planType,
          userId: user._id,
        }
      );

      window.location.replace(res.data.session.url);
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      toast.error(errorMessage);
    }
  };
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 overflow-auto flex justify-center items-start pt-10 z-[9999999999999]">
      <div className="relative bg-[#141414] w-full max-w-6xl rounded-lg shadow-lg p-6 justify-center items-center flex flex-col">
        <button
          onClick={() => {
            onClose();
          }}
          className="absolute top-2 right-2 text-white"
        >
          <IoMdClose />
        </button>
        <div className="bg-[#141414] lg:w-[25vw] text-white flex flex-col justify-between rounded-lg shadow-lg p-6 border-2 border-yellow-600">
          <div className="flex flex-col items-center">
            <h2 className="text-2xl font-semibold mb-2">FULL VIP CATALOG</h2>
            <h2 className="text-2xl font-normal mb-2">(mp3/wav)</h2>
            <div className="border-b-4 border-[#FB8500] w-8 mb-4"></div>
            <div className="py-4">
              <p className="text-4xl font-bold">$99</p>
            </div>
          </div>
          <div className="py-0">
            <p className="text-lg text-[#A9A9AA]">
              What You'll Get
            </p>
          </div>
          <ul className="flex flex-col gap-3 py-2">
            <li className="flex items-start gap-3 ">
              <img
                src={boxcircle}
                alt="checkmark"
                className="flex-shrink-0 mb-1"
              />
              <span>Instant access to ALL 300+ beats</span>
            </li>
            <li className="flex items-start gap-3">
              <img
                src={boxcircle}
                alt="checkmark"
                className="flex-shrink-0 mb-1"
              />
              <span>First access to ALL future beats</span>
            </li>
            <li className="flex items-start gap-3">
              <img
                src={boxcircle}
                alt="checkmark"
                className="flex-shrink-0 mb-1"
              />
              <span>
                Keep 100% royalties/profits to every track, all streaming platforms
              </span>
            </li>
            <li className="flex items-start gap-3">
              <img
                src={boxcircle}
                alt="checkmark"
                className="flex-shrink-0 mb-1"
              />
              <span>Non-exclusive licences</span>
            </li>
            <li className="flex items-start gap-3">
              <img
                src={boxcircle}
                alt="checkmark"
                className="flex-shrink-0 mb-1"
              />
              <span>MP3 and HQ WAV to every beat</span>
            </li>
          </ul>
          <div className="pt-9">
            <button
              className="py-3 px-4 bg-yellow-600 hover:scale-105 hover:transition-transform duration-200 rounded-md text-sm w-full text-white"
              onClick={() => handlePurchaseVipPlan()}
            >
              GET ACCESS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModelVip;
