import React from 'react'
import Layout from '../layout/Layout'
import MusicList from '../components/MusicList'
import MixingForm from '../components/MixingForm'
import Section from '../components/Section'

const Mixing = () => {
    return (
        <Layout>
                <Section className="bg-black min-h-screen">
                    <div className="lg:px-16 px-2 bg:black">
                        <div className="py-7">
                            <h1 className='text-center lg:leading-[3.8rem] py-3 text-3xl lg:text-6xl font-medium'>
                                Mixing Page Coming Soon
                            </h1>
                            <p className='lg:text-center tracking-wider lg:px-8 px-3 font-normal text-lg lg:text-4xl '>
                            In the meantime, please contact me at <b>lucas.vipbeats@gmail.com</b> if you have any requests, problems or recommendations. Thank you.
                            </p>
                          

                        </div>

                        


                        
                    </div>
                </Section>
            </Layout>
    )
}

export default Mixing