import React, { useEffect, useState } from "react";
import search from "../assets/search.svg";
import { FaBars } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCartShopping } from "react-icons/fa6";

const Navbar = ({ toggleSidebar }) => {
  const [profileColor, setProfileColor] = useState("#000000");
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { user } = useSelector((state) => state.user) || {};
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const stemsCartItems = useSelector((state) => state.stemscart.cartItems);

  useEffect(() => {
    const storedColor = localStorage.getItem("profileColor");
    if (storedColor) {
      setProfileColor(storedColor);
    } else {
      const getRandomDarkColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 4)];
        }
        return color;
      };

      const newColor = getRandomDarkColor();
      setProfileColor(newColor);
      localStorage.setItem("profileColor", newColor);
    }
  }, []);

  return (
    <div className="bg-black lg:px-10">
      <div className="flex items-center gap-4 md:justify-between px-4 py-4 md:py-8">
        <button className="md:hidden block z-20" onClick={toggleSidebar}>
          <FaBars className="text-white text-2xl" />
        </button>

        <div className="flex justify-end items-center gap-7 relative w-full">
          <div
            className=" cursor-pointer "
            onClick={() => {
              navigate("/cart");
            }}
          >
            <FaCartShopping color="#FF3E41" size={25} className="" />
            {
              <div className="w-4 h-4 rounded-full flex items-center justify-center bg-white absolute top-[14%] font-bold text-xs">
                {cartItems.length + stemsCartItems.length}
              </div>
            }
          </div>
          <div>
            {isAuthenticated ? (
              <Link to={"/user-dashboard"} className="text-white">
                <div className=" w-14 h-14 rounded-full text-white flex items-center justify-center ">
                  {user && user.profileImage ? (
                    <img
                      src={user.profileImage}
                      className="w-12 h-12 rounded-full flex items-center object-fill justify-center border p-1"
                      alt="User Profile"
                    />
                  ) : (
                    <div
                      style={{ backgroundColor: profileColor }}
                      className="w-[100%] h-[100%] rounded-full flex items-center justify-center text-lg"
                    >
                      {user && user.fullName ? user.fullName.slice(0, 2) : "US"}
                    </div>
                  )}
                </div>
              </Link>
            ) : (
              <Link to="/signup">
                <button className="text-red-600 border-2 hover:border-white font-medium hover:bg-white border-red-600 px-5 py-2 rounded-lg">
                  Signup/Login
                </button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
