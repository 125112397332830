import React, { useEffect, useState } from 'react'
import MusicList from '../../components/MusicList'
import UserLayout from '../../layout/UserLayout'
import Section from '../../components/Section'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFavorites } from '../../redux/favouriteSlice'
import { fetchUser } from '../../redux/userSlice'
import MusicPlayer from '../../components/MusicPlayer'
import PricingModel from "../../components/PricingModel";

const Favorite = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const favorites = useSelector((state) => state.favorites.favorites);
    const { loading } = useSelector((state) => state.beats);
    const [musicIndex,setMusicIndex] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedBeat,setSelectedBeat] = useState(null)
    const [isOpen,setIsOpen] = useState(false)
    const [isAllow, setIsAllow] = useState(false);
    const [isAllowStems, setIsAllowStems] = useState(false);
    
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        if (user?._id) {
            dispatch(fetchFavorites(user._id));
        }
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    const HandleClosePricing = () => {
        setIsOpen(false);
        setSelectedBeat(null);
      };

    return (
        <>
            <UserLayout>
                <Section>
                    <div className="md:px-10 pb-[10%]">
                        <div className="px-5 py-3">
                            <h1 className='text-white text-2xl font-bold py-8'>Favourites</h1>
                            <p className='text-lg text-[#9F9F9F]'>Tracks</p>
                        </div>
                        <div className="">
                            <MusicList exLength={0} section="favourites" beats={favorites} selectedBeat={selectedBeat} setSelectedBeat={setSelectedBeat} isOpen={isOpen} setIsOpen={setIsOpen} scrollToTop={scrollToTop} musicIndex={musicIndex} setMusicIndex={setMusicIndex} isPlaying={isPlaying} setIsPlaying={setIsPlaying} isAllow={isAllow}
              setIsAllow={setIsAllow}
              isAllowStems={isAllowStems}
              setIsAllowStems={setIsAllowStems}/>
                        </div>
                    </div>
                <MusicPlayer selectedBeat={selectedBeat} setSelectedBeat={setSelectedBeat} isOpen={isOpen} setIsOpen={setIsOpen} tracks={favorites} isPlaying={isPlaying} setIsPlaying={setIsPlaying} favorites={favorites} userId={user?._id} user={user} scrollToTop={scrollToTop} musicIndex={musicIndex} setMusicIndex={setMusicIndex}           isAllow={isAllow}
          setIsAllow={setIsAllow}
          isAllowStems={isAllowStems}
          setIsAllowStems={setIsAllowStems}/>

                </Section>
                {isOpen && (
        <PricingModel
          isOpen={isOpen}
          isAllow={isAllow}
          loading={loading}
          isAllowStems={isAllowStems}
          setIsAllow={setIsAllow}
          setIsAllowStems={setIsAllowStems}
          onClose={HandleClosePricing}
          user={user}
          beat={selectedBeat}
          scrollToTop={scrollToTop}
        />
      )}
            </UserLayout>

        </>
    )
}

export default Favorite