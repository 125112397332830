import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import Cards from "../components/Cards";
import MusicList from "../components/MusicList";
import MusicPlayer from "../components/MusicPlayer";
import search from "../assets/search.svg";
import Section from "../components/Section";
import { useDispatch, useSelector } from "react-redux";
import { fetchBeats } from "../redux/beatsSlice";
import { fetchUser } from "../redux/userSlice";
import { fetchFavorites } from "../redux/favouriteSlice";
import { LoaderComp } from "../UI/Loader";
import { useNavigate, Link } from "react-router-dom";
import { fetchCartItems } from "../redux/cartSlice";
import PricingModel from "../components/PricingModel";
import PricingModelVip from "../components/PricingModelVip";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LandingPage = () => {
  const dispatch = useDispatch();
  const { beats, loading } = useSelector((state) => state.beats);
  const favorites = useSelector((state) => state.favorites.favorites);
  const { user } = useSelector((state) => state.user);
  const [musicIndex, setMusicIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [selectedBeat, setSelectedBeat] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isVipOpen, setIsVipOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [tracks, setTracks] = useState([]); // Original beats list for the player
  const navigate = useNavigate();
  const [showGrid, setShowGrid] = useState(false);

  const [isAllow, setIsAllow] = useState(false);
  const [isAllowStems, setIsAllowStems] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  console.log("isssss", isAllow);

  useEffect(() => {
    if (!user) {
      dispatch(fetchUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(fetchBeats());
  }, [dispatch]);

  useEffect(() => {
    if (user?._id) {
      dispatch(fetchCartItems(user._id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user && user?.hasPasswordChanged === false) {
      navigate("/account-setting");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (beats.length > 0) {
      setTracks(beats);
    }
  }, [beats]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (loading) {
    return <LoaderComp />;
  }

  // Use filtered beats based on search query for the handleSelect function
  const handleSelect = (index) => {
    const beat = filteredBeats[index];
    setSelectedIndex(index);
    setMusicIndex(index);
    setSelectedBeat(beat);
    setIsPlaying(true);
  };

  // Filter and sort beats
  const filteredBeats = searchQuery
    ? beats.filter(
        (beat) =>
          beat.beatTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (beat.tags &&
            beat.tags.some((tag) =>
              tag.toLowerCase().includes(searchQuery.toLowerCase())
            ))
      )
    : beats;

  const SortBeats = (beats) => {
    return [...beats].sort((a, b) => {
      if (a.beatType === "Exclusive" && b.beatType !== "Exclusive") return -1;
      if (a.beatType !== "Exclusive" && b.beatType === "Exclusive") return 1;
      return 0;
    });
  };

  const exLength = filteredBeats
    ? filteredBeats.filter((beat) => beat?.beatType === "Exclusive").length
    : 0;

  const HandleClosePricing = () => {
    setIsOpen(false);
    setSelectedBeat(null);
  };

  const HandleCloseVipPricing = () => {
    setIsVipOpen(false);
  };

  return (
    <Layout>
      <Section>
        <div className="min-h-screen md:px-10">
          <div className="flex items-center justify-center py-5">
            <div className="flex items-center justify-center px-3 rounded-md bg-[#141414] relative w-full lg:w-1/2">
              <img src={search} alt="Search" className="" />
              <input
                type="text"
                placeholder="Search for beats or tags"
                className="py-3 rounded bg-transparent w-full px-3 focus:outline-none text-white"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <div className="py-0 px-0">
            <h1 className="text-white text-[35px] md:text-[52px] py-6 text-2xl font-bold text-center">
              NEW EXCLUSIVES
            </h1>
            <p className="text-[#FFFFFF] text-[13px] md:text-xl text-center ">
              <b>Get exclusive ownership of fresh, unused beats. </b>
            </p>
            <p className="text-[#FFFFFF] text-[13px] md:text-xl text-center">
              Secure the rights to your next hit before someone else does,
            </p>
            <p className="text-[#FFFFFF] text-[13px] md:text-xl text-center">
              or before quarantine period ends and the beat goes public.
            </p>
            <p className="text-[#FF3E41] text-[13px] md:text-xl text-center">
              <b>
                Includes Stems, FREE Mixing/Mastering (time-limited), and 100%
                Royalties
              </b>
            </p>
          </div>
          {!showGrid && (
            <div className="grid grid-cols-1 px-7 py-9 gap-5 mt-[-40px]">
              <Slider {...settings}>
                {filteredBeats
                  .filter((beat) => beat.beatType === "Exclusive")
                  .map((item, index) => (
                    <Cards
                      key={index}
                      beat={item}
                      userId={user?._id}
                      user={user}
                      selectedBeat={selectedBeat}
                      setSelectedBeat={setSelectedBeat}
                      musicIndex={musicIndex}
                      setMusicIndex={setMusicIndex}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      isSelected={musicIndex === index}
                      onSelect={() => handleSelect(index)}
                      number={index}
                    />
                  ))}
              </Slider>
            </div>
          )}
          {!showGrid && (
            <div className="text-center mt-4">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded relative top-[-45px]"
                onClick={() => setShowGrid(true)}
              >
                Show All Exclusives
              </button>
            </div>
          )}
          {showGrid && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-5 mt-5 px-5">
                {filteredBeats
                  .filter((beat) => beat.beatType === "Exclusive")
                  .map((item, index) => (
                    <Cards
                      key={index}
                      beat={item}
                      userId={user?._id}
                      user={user}
                      selectedBeat={selectedBeat}
                      setSelectedBeat={setSelectedBeat}
                      musicIndex={musicIndex}
                      setMusicIndex={setMusicIndex}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      isSelected={musicIndex === index}
                      onSelect={() => handleSelect(index)}
                      number={index}
                    />
                  ))}
              </div>
              <div className="text-center mt-4">
                <button
                  className="bg-red-500 text-white py-2 px-4 rounded"
                  onClick={() => setShowGrid(false)}
                >
                  Show Less
                </button>
              </div>
            </>
          )}
          <div className="py-4">
            <h1 className="text-white text-[35px] md:text-[52px] py-4 text-2xl font-bold text-center relative">
              VIP CATALOG
            </h1>
          </div>
          <div className="w-full mt-[-30px]">
            <MusicList
              exLength={exLength}
              selectedBeat={selectedBeat}
              setSelectedBeat={setSelectedBeat}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              beats={filteredBeats.filter(
                (beat) => beat.beatType !== "Exclusive"
              )}
              scrollToTop={scrollToTop}
              musicIndex={musicIndex}
              setMusicIndex={setMusicIndex}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              isAllow={isAllow}
              setIsAllow={setIsAllow}
              isAllowStems={isAllowStems}
              setIsAllowStems={setIsAllowStems}
            />
          </div>
        </div>
        {user && user.userType !== "vipUser" && (
          <div className="fixed bottom-36 left-0 w-full z-20 bg-yellow-500 text-black text-lg py-4 px-6 shadow-lg ">
            <div className="md:hidden  flex flex-col items-center justify-center">
              <button
                className="text-center w-full"
                onClick={() => setIsVipOpen(true)}
              >
                
                <p>
                <b> Get FULL access to my VIP catalog 
                <br />
                of  300+ beats for $99</b>
                
                  <br />
                  (click here)
                </p>
              </button>
            </div>

            <div className="hidden md:flex items-center justify-center">
              <p className="mr-3">
                Get FULL access to my VIP catalog of{" "}
                <b>300+ beats </b>
                 for <b>$99</b>
              </p>
              <button
                className="bg-black text-white py-2 px-4 rounded"
                onClick={() => setIsVipOpen(true)}
              >
                GET ACCESS
              </button>
            </div>
          </div>
        )}

        {user === undefined && (
          <div className="fixed bottom-40 lg:bottom-32 left-0 w-full z-20 bg-yellow-500 text-black text-lg py-4 px-6 shadow-lg">
            <Link
              to="/signup"
              className="flex flex-col items-center justify-center md:hidden"
            >
              <p className="text-center">
              <b>Get FULL access to my VIP catalog of 300+ Beats</b>
                <br />
                (click here)
              </p>
            </Link>

            <div className="hidden md:flex items-center justify-center ">
              <p className="mr-3">
                Get <b>FULL access</b> to VIP VAULT and get{" "}
                <b>300+ beats upfront</b> for
                just <b>$99</b>
              </p>
              <Link to="/signup">
                <button className="bg-black text-white py-2 px-4 rounded">
                  GET ACCESS
                </button>
              </Link>
            </div>
          </div>
        )}

        <MusicPlayer
          selectedBeat={selectedBeat}
          setSelectedBeat={setSelectedBeat}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          tracks={SortBeats(filteredBeats)}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          favorites={favorites}
          userId={user?._id}
          user={user}
          scrollToTop={scrollToTop}
          musicIndex={musicIndex}
          setMusicIndex={setMusicIndex}
          isAllow={isAllow}
          setIsAllow={setIsAllow}
          isAllowStems={isAllowStems}
          setIsAllowStems={setIsAllowStems}
        />
      </Section>
      {isOpen && (
        <PricingModel
          isOpen={isOpen}
          isAllow={isAllow}
          loading={loading}
          isAllowStems={isAllowStems}
          setIsAllow={setIsAllow}
          setIsAllowStems={setIsAllowStems}
          onClose={HandleClosePricing}
          user={user}
          beat={selectedBeat}
          scrollToTop={scrollToTop}
        />
      )}

      {isVipOpen && (
        <PricingModelVip user={user} onClose={HandleCloseVipPricing} />
      )}
    </Layout>
  );
};

export default LandingPage;
